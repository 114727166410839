<!--  -->
<template>
    <div>
        <div align="center" class="">
            <div>
                <span style="display: inline-block; font-size: 24px">
                    八导联24小时心电检测报告
                </span>
                <img style="width: 120px; vertical-align: middle; display: inline-block"
                    src="../../../../../public/img/logo_xingou.png" />
            </div>

            <el-row class="info">
                <el-col :span="3"><span style="font-size:18px"> 姓名：{{ report_title.realName }} </span></el-col>
                <el-col :span="3"><span style="font-size:18px"> 性别：{{ report_title.gender }} </span></el-col>
                <el-col :span="2"><span style="font-size:18px"> 年龄：{{ report_title.age }} </span></el-col>
                <el-col :span="10"><span style="font-size:18px">
                        时间：{{ createTime }} 至 {{ report_title.endTime }}
                    </span></el-col>
                <el-col :span="5" style="font-size:18px"><span> 总时间：{{ report_title.totalTime }} </span></el-col>
            </el-row>
            <hr style="height:1px;border:none;border-top:1px solid #555555;" />
            <el-tabs>
                <el-tab-pane label="心电图">
                    <div class="scroll" align="center">
                        <div id="myChart" ref="ecg1"
                            :style="'width:' + (screenWidth - 320) + 'px; height:' + 0.34 * (screenWidth - 320) + 'px'">
                        </div>
                    </div>
                <!-- <el-dialog title="24小时心电检测报告——心电图" :visible.sync="dialogVisible" :fullscreen="true" center>
              <div id="myChart2" ref="fullChart" :style="'width:100%;height:' + (screenHeight - 110) + 'px'"></div>
                    </el-dialog> -->
                    <div style="position:relative; height:70px">
                        <!-- 颜色指示条  -->
                        <div style="position:absolute;z-index:1;transform: translate(-50%);left: 50%;">
                            <canvas id="score_indicate" :width="+ (screenWidth - 430)" height="30">您的浏览器不支持canvas</canvas>
                        </div>
                        <!-- 时间滑块slider -->
                        <div class="block"
                            :style="'width:' + (screenWidth - 430) + 'px; position:absolute;z-index:2;transform: translate(-50%);left: 50%;'">
                            <el-slider class="timeslider" v-model="nowIndex" :max="maxIndex" :step="1"
                                :format-tooltip="formatTooltip" :marks="setMarker" show-stops @change="getNewData">
                            </el-slider>
                            <br>
                            <br>
                            <!-- <span class="demonstration">拖动选择时间点</span> -->
                        </div>
                    </div>
                    <div class="indicate_legend">
                        <br>
                        <span class="blank" style="background-color:#F56C6C ">
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style=" "> 心电图严重异常</span>
                        <span style=" ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span
                            style="background-color:rgb(234 215 79) ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style=" "> 疑似度较高</span>
                        <span style=" ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style="background-color:#67C23A ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                        <span style=" "> 健康</span>
                        <span style=" ">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                    </div>
                </el-tab-pane>

                <el-tab-pane label="散点图">
                    <div style="border:solid;width: 1200px;height:auto">
                        <div style="border-top:solid;border-bottom:solid;">
                            <el-row>
                                <el-col :span="12">
                                    <div style="margin-top:0px;border-right: solid 2px black; width:600px; height:620px;">
                                        <div style="padding:10px; font-size: 20px;"><b>我的心电散点图</b></div>
                                        <div id="Scatter"
                                            style="width:550px; height:550px;margin: 10px;border:solid 3px #428bca;border-radius: 20px;">
                                        </div>
                                    </div>
                                </el-col>
                                <el-col :span="12">
                                    <div style="padding:10px;font-size: 20px;"><b>正常心电散点图</b></div>
                                    <img src="../../../../../public/img/scatter/1.png" class="image"
                                        style="width: 550px; height:550px;margin: 10px;padding:15px; border:solid 3px firebrick;"
                                        @click="ScatterHelp_Normal()">

                                </el-col>
                            </el-row>
                        </div>
                        <div class="images clearfix">
                            <span style="display: inline-block;padding:10px;font-size:20px"><b>异常心电散点图图库</b></span>
                            <div v-for="{ id, image_rows } in images" :key="id">
                                <el-row type="flex" justify="center">
                                    <el-col :span="5" v-for="{ img_name, source, id } in image_rows" :key="source">
                                        <span class="image_name">
                                            <td class="image_name_td" style="middle">{{ id }} {{ img_name }}</td>

                                        </span>
                                        <img :src="source" class="image" @click="ScatterHelp(id)" />
                                    </el-col>
                                </el-row>
                            </div>
                        </div>
                    </div>

                </el-tab-pane>
            </el-tabs>
        </div>
        <!-- 散点图-帮助文档 -->
        <el-dialog :title="scatterHelp.title" :visible.sync="dialogVisible2" width="45%">
            <h3>概述</h3>
            <p style="white-space:pre-line;font-size: 16px;">
                {{ scatterHelp.abstract }}
            </p>
            <!-- <input type="file" @change="loadTextFromFile"> -->
            <h3>散点图形态</h3>
            <p style="white-space:pre-line;font-size: 16px;">
                {{ scatterHelp.morphology }}
            </p>
            <img :src=img_soucre width="100%" />
            <p style="white-space:pre-line;font-family: KaiTi;font-size: 16px;">
                {{ scatterHelp.Annotation }}
            </p>
            <p style="white-space:pre-line;font-size: 14px; text-align:right">
                参考文献：李方洁, 向晋涛. 心电散点图[M]. 人民卫生出版社, 2014.
            </p>
        </el-dialog>

        <!-- 什么是散点图？ -->
        <el-dialog append-to-body title="散点图定义与作图方法" :visible.sync="dialogVisible3" width="45%"
            :modal-append-to-body="false" :close-on-click-modal="false">
            <p style="font-size: 16px;">
                心电散点图是用非线性的图形方法Lorenzplot描记的连续心电RR间期图，因图形由散点组成，又称散点图（scatterplot、scattermap）。连续RR间期所代表的心脏节律是人体时间序列动态变化的重要表现形式，其散点制作的方法具有迭代计算（次递的从变量的原值推出它的一个新值）的独特性质。设二维坐标系中横坐标X=n，纵坐标Y=n+1。
            </p>
            <p style="font-size: 16px;">
                以图1为例：图1
                A是正常窦性心律动态心电图片段；取第一个RR间期为X值，第二个RR间期为Y值，做成P1点；再取第二个RR间期为X值，取第三个RR间期为Y值，做成P2点；再取第三个RR间期为X值，取第四个RR间期为Y值，做成P3点（图1B）；然后依此法追踪制作成24小时心电散点（图1C为示意图）；图1D是真实的24小时窦性心律的心电散点图。
            </p>
            <img :src="require(`../../../../../public/img/scatterInfo/0.png`)" width="100%" />
            <p style="white-space:pre-line;font-family: KaiTi;font-size: 16px; text-align:center">
                图1 作图方法
            </p>
            <p style="white-space:pre-line;font-size: 16px; text-align:right">
                参考文献：李方洁, 向晋涛. 心电散点图[M]. 人民卫生出版社, 2014.
            </p>
        </el-dialog>
    </div>
</template>
  
<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
import { scattercontent } from '../../../../utils/ScatterContent.js'
const leads = ["I", "II", "III", "aVR", "aVL", "aVF", "V1", "V5"];//导联名称
const leadsInterval = 2; // 两个导联之间的间隔（mv）
const leadsValue = [6.5 / 5, 73 / 55, 4 / 2.5, 8.5 / 6.5, 1.7, 7 / 5, 8 / 6, 13 / 4.5];//导联标定
const sourceImages = []
const images_name = ["正常心电散点图", "窦性心律伴室上性早搏（房性早搏）", "窦性心律伴室性早搏四分布", "窦性心律伴室性早搏三分布", "窦性心律伴室性早搏二分布", "窦性心动过缓",
    "窦性心动过速", "窦性心律不齐", "持续性室上性心动过速伴传出阻滞", "阵发性室上性心动过速", "有插入性室早的室性并行心律",
    "房性并行心律", "交界性并行心律", "II度I型房室传导阻滞", "II度II型房室传导阻滞", "窦房文氏传导",
    "I度窦房传导阻滞", "II度II型窦房传导阻滞", "窦性逸搏", "房性逸搏", "窦性停搏伴交界性逸搏",
    "室性逸搏", "心房感知、心室起搏心律", "心房感知、心室起搏心律合并房性早搏", "窦性早搏和窦性逸博", "阵发性室性心动过速",
    "心房颤动合并的交界性逸搏", "室性并行心律", "房颤", "房扑"];
for (let row = 0; row < 6; row++) {
    let images_row = []
    for (let i = 2; i < 7; i++) {
        if ((i + row * 5) > 30) {
            continue
        }
        let img_name = images_name[i + row * 5 - 1]
        images_row.push({
            img_name: img_name,
            source: require(`../../../../../public/img/scatter/${i + row * 5}.png`),
            id: (i + row * 5)
        })
    }
    sourceImages.push({
        id: row,
        image_rows: images_row,
    })
}


export default {

    //import引入的组件需要注入到对象中才能使用
    components: {},
    props: {
        userId: Number,  // 【其他平台】读取的属性
        recordId: String, // 【其他平台】读取的属性
        createTimeId: String, // 【其他平台】读取的属性
        durationId:  Number,  // 【其他平台】读取的属性
    },
    data() {
        //这里存放数据
        return {
            uid: this.userId,  // 从props中赋值
            deid: this.recordId, // 从props中赋值
            createTime: this.createTimeId, // 从props中赋值
            duration:this.durationId, // 从props中赋值
            dialogVisible: false,
            dialogVisible2: false,
            dialogVisible3: false,
            screenHeight: window.innerHeight,
            screenWidth: window.innerWidth,
            scatterHelp: {
                title: '',
                abstract: '',
                morphology: '',
                Annotation: '',
            },
            img_soucre: '',
            index: 1,
            report_title: {
                realName: "",
                gender: "",
                age: '',
                date: "2021-01-01",
                xinlv: 0,
                pr: 0,
                qt: 0,
                qtc: 0,
                p: 0,
                qrs: 0,
                startTime: "",
                titleTime: "",
                endTime: "",
                totalTime: "",
                yData: [],
                diseases: [],
                results: [],
            },
            ecgData: '',
            conclusionData: '',
            createTimeList: [],
            nowIndex: 0,
            maxIndex: 0,
            dataLen: 0,

            // 散点图数据
            r_num: 0,
            data_rList: [],
            // 散点图图库文件变量
            images: sourceImages,
            ECG_option: {},
            base: '',
            myChart: {},
            datazoom_y: 75,

        };
    },
    //监听属性 类似于data概念
    computed: {
        // 时间轴的刻度点
        setMarker() {
            // 模拟每10个点加标记
            let marks = {};
            var interval_mark = []
            if (this.maxIndex <= 50) {
                interval_mark = 5
            }
            else if (this.maxIndex >= 50 && this.maxIndex < 100) {
                interval_mark = 10
            }
            else if (this.maxIndex >= 100 && this.maxIndex < 400) {
                interval_mark = 40
            } else if (this.maxIndex >= 400 && this.maxIndex < 700) {
                interval_mark = 100
            } else if (this.maxIndex >= 700 && this.maxIndex < 1500) {
                interval_mark = 200
            } else if (this.maxIndex >= 1500) {
                interval_mark = 400
            }
            for (let i = 0; i <= this.maxIndex; i++) {
                if (i % interval_mark == 0) {
                    marks[i] = this.createTimeList[i];
                }
            }
            // console.log(this.createTimeList)
            // label的bug在这里，传入最后一个标记点就会报错，但如果手动输入一个字符串，不会报错↓
            // marks[this.maxIndex]="2022-07-07 14:55:24"

            return marks;
        },


    },
    //监控data中的数据变化
    watch: {
        screenHeight(val) {
            this.screenHeight = val;
        },
        screenWidth(val) {
            this.screenWidth = val;
        },
    },
    //方法集合
    methods: {
        // 心电图
        EndTime() {
            var startTime = +new Date(this.createTime.substring(0, 4),
                this.createTime.substring(5, 7) - 1,
                this.createTime.substring(8, 10),
                this.createTime.substring(11, 13),
                this.createTime.substring(14, 16),
                this.createTime.substring(17, 19));
            var endTime = new Date(startTime + this.duration * 1000)
            let y = endTime.getFullYear();
            let MM = endTime.getMonth() + 1;
            MM = MM < 10 ? ('0' + MM) : MM;
            let d = endTime.getDate();
            d = d < 10 ? ('0' + d) : d;
            let h = endTime.getHours();
            h = h < 10 ? ('0' + h) : h;
            let m = endTime.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            let s = endTime.getSeconds();
            s = s < 10 ? ('0' + s) : s;
            // return  MM + '-' + d + ' ' + h + ':' + m + ':' + s+'.'+ss;   //显示到毫秒
            this.report_title.endTime = y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s;  //显示到秒

        },
        TotalTime() {
            var totalTime = new Date(this.duration * 1000)
            let d = totalTime.getDate() - 1;
            let h = totalTime.getHours() - 8;
            if (h < 0) {
                d = d - 1
                h = 24 + h
            }
            h = h < 10 ? ('0' + h) : h;

            let m = totalTime.getMinutes();
            m = m < 10 ? ('0' + m) : m;
            let s = totalTime.getSeconds();
            s = s < 10 ? ('0' + s) : s;
            if (d != 0) {
                this.report_title.totalTime = d + '天' + h + '小时' + m + '分' + s + '秒';  //显示到秒
            }
            else if (h == 0) {
                if (m != 0) {
                    this.report_title.totalTime = m + '分' + s + '秒';  //显示到秒
                }
                else {
                    this.report_title.totalTime = s + '秒';  //显示到秒
                }

            }
            else {
                this.report_title.totalTime = h + '小时' + m + '分' + s + '秒';  //显示到秒
            }
        },

        // 标志点绘制
        DrawMarkPoint() {
            var MKList = [this.plist, this.qlist, this.rlist, this.slist, this.s2list, this.tlist]
            var markpoints_nkx = new Array(8)
            var markpoints_nky = new Array(8)

            for (let n = 0; n < 8; n++) { //这一层是不同导联
                var markpoints_kx = new Array(6)
                var markpoints_ky = new Array(6)
                if (n == 6 & this.point_flag) {
                    for (let ii = 0; ii < this.V1_points.length; ii++) {
                        for (let jj = 0; jj < 6; jj++) {
                            MKList[jj][ii] = this.V1_points[ii][jj]
                        }
                    }

                    console.log(MKList)
                }
                if (n == 7 & this.point_flag) {

                    // MKList = this.V5_points
                    for (let ii = 0; ii < this.V5_points.length; ii++) {
                        for (let jj = 0; jj < 6; jj++) {
                            MKList[jj][ii] = this.V5_points[ii][jj]
                        }
                    }
                }
                for (let k = 0; k < 6; k++) {     // 这一层for选择标志点p q r s s2 t
                    var numecg_sum = 0
                    var markpoints_x = []
                    var markpoints_y = []
                    for (let i = this.nowIndex; i < this.dataLen + this.nowIndex; i++) {   // 这一层for将所有标志点分为dataLen个为一组（不满dataLen的跳过）
                        if (i != this.nowIndex) {
                            numecg_sum = numecg_sum + this.numecg[i]      // 由于后端每个小段的标志点都是从0开始，因此在大段里需要加上每个小段的长度
                        }
                        if (MKList[k][i] == undefined) {
                            continue
                        }
                        for (let j = 0; j < MKList[k][i].length; j++) {     // 这一层for将每个小段里的每个标志点push进数据集中
                            if (this.tlist[i][j] == 0) {
                                continue
                            }
                            markpoints_x.push(
                                this.x[Number(MKList[k][i][j]) + numecg_sum]
                            );
                            markpoints_y.push(
                                this.ecgData[leads[n]][Number(MKList[k][i][j]) + numecg_sum]
                            );
                        }
                    }
                    //标志点的横坐标,不同导联都是一样的--->不一样
                    markpoints_kx[k] = markpoints_x

                    markpoints_ky[k] = markpoints_y

                }
                //标志点的纵坐标--三维数组
                markpoints_nkx[n] = markpoints_kx
                markpoints_nky[n] = markpoints_ky
            }
            this.MK_x = new Array(6)
            this.MK_y = new Array(6)
            for (let k = 0; k < 6; k++) {
                var MK_x = []
                var MK_y = []
                for (let n = 0; n < 8; n++) {
                    MK_x = MK_x.concat(markpoints_nkx[n][k])
                    MK_y = MK_y.concat(markpoints_nky[n][k])
                }
                this.MK_x[k] = MK_x
                this.MK_y[k] = MK_y
            }
            // console.log(this.MK_x)
            // console.log(this.MK_y)

        },
        // 结论数据处理
        ConclusionPageData() {
            var nowConData = []
            var numecg_sum = 0
            this.nowConList = new Array(this.Len_diseaseList)
            for (let i = this.nowIndex; i < this.dataLen + this.nowIndex; i++) {
                if (this.numecg[i + 1] == undefined) {
                    continue
                }
                numecg_sum = numecg_sum + this.numecg[i + 1]// 每个元素i是前面所有numecg的和
                nowConData.push(numecg_sum)
            }
            this.nowConData = nowConData   //结论框的横坐标

            this.conclusion_y = Array(this.newTimeList.length).fill(6);

            for (let k = 0; k < this.Len_diseaseList; k++) {
                var nowConList = []
                for (let i = this.nowIndex; i < this.dataLen + this.nowIndex; i++) {
                    if (this.ConList[k][i] == undefined) {
                        continue
                    }
                    nowConList.push(this.ConList[k][i])
                }
                this.nowConList[k] = nowConList   //结论14项项目内容+method
            }
            this.nowConList.push(this.nowConData, this.conclusion_y, this.newTimeList)
            // console.log(111111111)
            // console.log(this.nowConList)
        },
        initECGChart(domName) {
            this.myChart = this.$echarts.init(domName);
            this.myChart.showLoading({
                text: '请稍等, 正在为您加载数据',
                zlevel: 0,
                // 字体大小。
                fontSize: 20,
                // 是否显示旋转动画（spinner）。
                showSpinner: true,
                // 旋转动画（spinner）的半径。
                spinnerRadius: 16,
                // 旋转动画（spinner）的线宽。
                lineWidth: 8,
            })
            // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例

        },
        UpdateECGChart() {
            // 八导联索引

            // 心电图的x轴坐标
            this.x = [];
            for (let i = 0; i < this.ecgData[leads[0]].length; i++) {
                this.x.push(i + 200);
            }

            // 标志物的x轴、y轴坐标
            var a = [0, 0, 1, 1, 0, 0]
            var b = []
            var c = []
            var d = [0, 1.3, 1.3, 2.6, 2.6, 3.9, null]
            var e = []
            for (let i = 0; i < 8; i++) {
                b = a.map(function (value) {
                    return (value - leadsInterval * i);
                });
                b = b.concat(null)
                c = c.concat(b)
                e = e.concat(d)
            }
            this.item_x = e
            this.item_y = c
            // console.log(this.ecgData[leads[0]])
            this.ECG_option.xAxis[0].max = []
            this.ECG_option.dataset[0].source = []
            this.ECG_option.xAxis[0].max = this.ecgData.length
            this.ECG_option.dataset[0].source = [this.x, this.ecgData[leads[0]], this.ecgData[leads[1]], this.ecgData[leads[2]], this.ecgData[leads[3]],
            this.ecgData[leads[4]], this.ecgData[leads[5]], this.ecgData[leads[6]], this.ecgData[leads[7]]]

            this.DrawMarkPoint()
            this.ConclusionPageData()
            // console.log(this.newTimeList)
            // datazoom滑块左右两侧时间刻度
            var base = +new Date(this.showTimeList[0].substring(0, 4),
                this.showTimeList[0].substring(5, 7) - 1,
                this.showTimeList[0].substring(8, 10),
                this.showTimeList[0].substring(11, 13),
                this.showTimeList[0].substring(14, 16),
                this.showTimeList[0].substring(17, 19));
            // TODO: 每个20s的红黄蓝标记后面新的数据中会专门存这个结论，到时候直接调用

            // 横轴坐标
            this.ECG_option.dataZoom[0].labelFormatter = []
            this.ECG_option.dataset[1].source = []
            this.ECG_option.dataset[2].source = []
            this.ECG_option.dataset[3].source = []
            this.ECG_option.dataset[4].source = []
            this.ECG_option.dataset[5].source = []
            this.ECG_option.dataset[6].source = []
            this.ECG_option.dataset[7].source = []
            this.ECG_option.dataset[8].source = []
            this.ECG_option.dataZoom[0].labelFormatter = function (value) {
                let date = new Date(base + value * 2)  // 2ms一个点
                let y = date.getFullYear();
                let MM = date.getMonth() + 1;
                MM = MM < 10 ? ('0' + MM) : MM;
                let d = date.getDate();
                d = d < 10 ? ('0' + d) : d;
                let h = date.getHours();
                h = h < 10 ? ('0' + h) : h;
                let m = date.getMinutes();
                m = m < 10 ? ('0' + m) : m;
                let s = date.getSeconds();
                s = s < 10 ? ('0' + s) : s;
                let ss = date.getMilliseconds();
                ss = ss < 10 ? ('00' + ss) : ss;
                ss = (ss > 10 && ss < 100) ? ('0' + ss) : ss;
                // return  MM + '-' + d + ' ' + h + ':' + m + ':' + s+'.'+ss;   //显示到毫秒
                return y + '-' + MM + '-' + d + '\n' + h + ':' + m + ':' + s;  //显示到秒
            },
                // 结论数据（包含疾病项目、时间、方法）
                this.ECG_option.dataset[1].source = this.nowConList

            this.ECG_option.dataset[2].source = [
                this.MK_x[0],
                this.MK_y[0]
            ]
            this.ECG_option.dataset[3].source = [
                this.MK_x[1],
                this.MK_y[1]
            ]
            this.ECG_option.dataset[4].source = [
                this.MK_x[2],
                this.MK_y[2]
            ]
            this.ECG_option.dataset[5].source = [
                this.MK_x[3],
                this.MK_y[3]
            ]
            this.ECG_option.dataset[6].source = [
                this.MK_x[4],
                this.MK_y[4]
            ]
            this.ECG_option.dataset[7].source = [
                this.MK_x[5],
                this.MK_y[5]
            ]
            this.ECG_option.dataset[8].source = [
                this.item_x,
                this.item_y
            ]
            this.myChart.hideLoading();

            this.myChart.setOption(this.ECG_option);


            window.addEventListener("resize", () => {  //根据屏幕进行实时绘制
                this.myChart.resize();
            });
        },
        //结论参数
        _disease(name, unit, result) {
            this.name = name; //疾病名称
            this.result = result
            this.unit = unit; //单位
        },
        getConclusion() {
            // 根据每条心电记录的deid(八导联）获取该条的结论
            this.$http({
                url: this.$http.adornUrl('/personal/dynamicecg8/getConclusion'),
                method: 'get',
                params: this.$http.adornParams({ deid: this.deid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log("看看八导联holter结论")
                    console.log(data)
                    this.conclusionData = data.conclusionData4eightLeads
                    // 获取到结论后，判断是新还是老的存储形式,新的就处理一下
                    if (this.conclusionData[this.conclusionData.length - 1] == ',') {
                        this.conclusionData = "[" + this.conclusionData.substring(0, this.conclusionData.length - 1) + "]";
                    }

                    // 将20s一条的结论转换为对象数组，然后提取出所有记录的createTime
                    this.conclusionData = JSON.parse(this.conclusionData)
                    // console.log(this.conclusionData)

                    var diseaseList = {
                        "xinlv": new this._disease("心率", "次/min", ""),
                        "xdgs": new this._disease("窦性心动过速", "%", ""),
                        "xdgh": new this._disease("窦性心动过缓", "%", ""),
                        "xlbq": new this._disease("窦性心律不齐", "%", ""),
                        "fxzb": new this._disease("房性早搏", "个", ""),
                        "jjxzb": new this._disease("交界性早搏", "个", ""),
                        "sxzb": new this._disease("室性早搏", "个", ""),
                        "ssxxdgs": new this._disease("室上性心动过速", "%", ""),
                        "sxxdgs": new this._disease("室性心动过速", "%", ""),
                        "fxyb": new this._disease("交界性逸搏", "个", ""),
                        "sxyb": new this._disease("室性逸搏", "个", ""),
                        "fc": new this._disease("房颤", "%", ""),
                        "fp": new this._disease("房扑", "%", ""),
                        "zszcdzz": new this._disease("左束支传导阻滞", "%", ""),
                        "yszcdzz": new this._disease("右束支传导阻滞", "%", ""),
                        "dxyjzhz": new this._disease("典型预激综合征", "%", ""),
                        "dprzhz": new this._disease("短PR综合征", "%", ""),
                        "byxyjzhz": new this._disease("变异型预激综合征", "%", ""),
                        "sttg": new this._disease("ST段抬高", "%", ""),
                        "styd": new this._disease("ST段压低", "%", ""),
                        "tbxf": new this._disease("T波倒置", "%", ""),
                        "tbgdyc": new this._disease("T波高尖", "%", ""),
                        "blxqb": new this._disease("病理性Q波", "%   ", ""),
                        "method": new this._disease("模型方法", "", ""),
                        // "grb":"干扰波"//判断是否是干扰波 0:正常 1:干扰波
                    };
                    this.Len_diseaseList = Object.keys(diseaseList).length
                    this.tlist = new Array(this.conclusionData.length)
                    this.plist = new Array(this.conclusionData.length)
                    this.qlist = new Array(this.conclusionData.length)
                    this.rlist = new Array(this.conclusionData.length)
                    this.slist = new Array(this.conclusionData.length)
                    this.s2list = new Array(this.conclusionData.length)
                    this.V1_points = new Array(this.conclusionData.length)
                    this.V5_points = new Array(this.conclusionData.length)
                    this.point_flag = true
                    this.numecg = new Array(this.conclusionData.length)
                    this.numecg[0] = 0
                    this.score = new Array(this.conclusionData.length)
                    var endTimeList = new Array(this.conclusionData.length)

                    // 新建结论列表（用于显示在心电图附近）
                    this.ConList = new Array(this.Len_diseaseList)
                    // console.log(this.ConList)
                    for (let m = 0; m < this.Len_diseaseList; m++) {
                        this.ConList[m] = new Array(this.conclusionData.length)

                    }
                    var point_name = ["plist", "qlist", "rlist", "slist", "s2list", "tlist"]
                    for (let i = 0; i < this.conclusionData.length; i++) {
                        // console.log(1111112)

                        endTimeList[i] = this.conclusionData[i].time  //直接接收到的其实是每一段的结束时间
                        this.tlist[i] = this.conclusionData[i].tlist.substring(0, this.conclusionData[i].tlist.length - 1).split(',')
                        this.plist[i] = this.conclusionData[i].plist.substring(0, this.conclusionData[i].plist.length - 1).split(',')
                        this.qlist[i] = this.conclusionData[i].qlist.substring(0, this.conclusionData[i].qlist.length - 1).split(',')
                        this.rlist[i] = this.conclusionData[i].rlist.substring(0, this.conclusionData[i].rlist.length - 1).split(',')
                        this.slist[i] = this.conclusionData[i].slist.substring(0, this.conclusionData[i].slist.length - 1).split(',')
                        this.s2list[i] = this.conclusionData[i].s2list.substring(0, this.conclusionData[i].s2list.length - 1).split(',')
                        this.numecg[i + 1] = this.conclusionData[i].numecg
                        this.score[i] = this.conclusionData[i].score
                        // try {
                        let point_V1_V5 = JSON.parse(this.conclusionData[i].otherleadslist)

                        var point_V1 = JSON.parse(point_V1_V5["V1"])
                        var point_V5 = JSON.parse(point_V1_V5["V5"])
                        this.V1_points[i] = new Array();
                        this.V5_points[i] = new Array();
                        for (let j = 0; j < point_name.length; j++) {
                            // console.log(point_name[j])
                            let name = point_name[j]
                            if (point_V1[name] === undefined || point_V1[name].length < 2) {
                                name = "rlist"
                                let list_len = point_V1[name].substring(0, point_V1[name].length - 1).split(",").length
                                let useless_point = []
                                for (let mm = 0; mm < list_len; mm++) {
                                    useless_point[mm] = 0
                                }
                                this.V1_points[i][j] = useless_point
                                continue
                            }

                            // console.log(point_V1[name].substring(0, point_V1[name].length-1).split(","))
                            this.V1_points[i][j] = point_V1[name].substring(0, point_V1[name].length - 1).split(",")
                            this.V5_points[i][j] = point_V5[name].substring(0, point_V5[name].length - 1).split(",")
                        }
                        // console.log(9999)
                        // console.log(this.V1_points)
                        // console.log(this.V5_points)
                        // } catch (error) {
                        //     this.point_flag=false;
                        //     console.log("转换失败");


                        // }

                        // 获取所有的结论数据（包含疾病项目、时间、方法）
                        for (let m = 0; m < this.Len_diseaseList; m++) {
                            // console.log(this.conclusionData[i][Object.keys(diseaseList)[m]])
                            this.ConList[m][i] = this.conclusionData[i][Object.keys(diseaseList)[m]]
                        }

                        // 计算散点图数据 （有误差算法）
                        let r_count = this.rlist[i].length
                        for (let r_ind = 1; r_ind < r_count - 1; r_ind++) {
                            let sca_coor = new Array(2)
                            sca_coor[0] = (this.rlist[i][r_ind] - this.rlist[i][r_ind - 1]) * 1000 / 500
                            sca_coor[1] = (this.rlist[i][r_ind + 1] - this.rlist[i][r_ind]) * 1000 / 500
                            this.r_num += 1
                            this.data_rList.push(sca_coor)
                        }
                    }
                    console.log('score分数')
                    console.log(this.score)

                    var createTimeList = [this.createTime].concat(endTimeList) //连接了起始时间
                    this.createTimeList = createTimeList
                    this.maxIndex = this.createTimeList.length - 2 //加入了初始时间，所有最后一个结束时间也没有用到了，结束时间只做查询用
                    // 获取到所有的createTime后，取前9个20秒(3分钟)作为初始数据 
                    this.dataLen = 3 // 定义几个20s
                    this.newTimeList = this.createTimeList.slice(1, this.dataLen + 1) //实际查询的时间（以结束时间作为索引）
                    // console.log(this.newTimeList)
                    this.showTimeList = this.createTimeList.slice(0, this.dataLen)    //展示的时间（以起始时间展示到前端页面上）
                    this.nowIndex = 0
                    this.canvas_score();
                    this.ScatterChart(document.getElementById("Scatter"))
                    this.getData(this.newTimeList)
                    // TODO: 每个20s的红黄蓝标记后面新的数据中会专门存这个结论，到时候直接调用
                    this.canvas_score();
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        },
        getData(timeList) {
            var filter_timeList = timeList.filter(function (s) {
                return s && s.trim();
            })
            // 传入一个时间数组，返回该数组中所列时间的拼接后数据
            this.$http({
                url: this.$http.adornUrl('/personal/dynamicecg8/getBatchData4EightLeads'),
                method: 'post',
                data: this.$http.adornData({
                    deid: this.deid,
                    startTime: filter_timeList,
                }),
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    console.log(data)
                    this.ecgData = data.dynamicEcgData4eightLeads
                    for (let i = 0; i < 8; i++) {
                        // this.ecgData[leads[i]] = this.ecgData[leads[i]].split(',').slice(210).map(function (index) {
                        this.ecgData[leads[i]] = this.ecgData[leads[i]].split(',').map(function (index) {

                            // return (index / 50000 * leadsValue[i] - 4 * i);      //mV信号的转换
                            return (index / 10000 - leadsInterval * i);      //mV信号的转换

                        });
                    }

                    // console.log(this.ecgData)
                    this.UpdateECGChart()
                    // TODO: 获取到数据，然后开始绘制
                } else {
                    this.$message.error(data.msg)
                }
            })
        },
        formatTooltip(val) {
            // elementui中的滑块组件所使用的函数
            return this.createTimeList[val];
        },
        getNewData(e) {
            // console.log(e)
            this.nowIndex = e

            // 从当前位置读取后dataLen个20s的数据，读取个数可以自己根据后端响应情况定义
            this.newTimeList = []
            this.showTimeList = []
            for (let i = 0; i < this.dataLen; i++) {
                if (this.createTimeList[i + e + 1] == undefined) {
                    continue
                }
                this.newTimeList[i] = this.createTimeList[i + e + 1]
                this.showTimeList[i] = this.createTimeList[i + e]
            }
            this.getData(this.newTimeList)
        },
        canvas_score() {
            var data_len = this.createTimeList.length
            // console.log("长度")
            // console.log(data_len)
            let can = document.getElementById('score_indicate')
            let indicate = can.getContext('2d');
            // 指示条总长度为2000
            for (let i = 0; i < data_len; i++) {
                if (this.score[i] == 0) {
                    indicate.strokeStyle = "#67C23A"
                    indicate.fillStyle = "#67C23A";
                }
                if (this.score[i] == 50) {
                    indicate.strokeStyle = "rgb(234 215 79)"
                    indicate.fillStyle = "rgb(234 215 79)";

                }
                if (this.score[i] == 100) {
                    indicate.strokeStyle = "#F56C6C"
                    indicate.fillStyle = "#F56C6C";

                }
                indicate.lineWidth = 2
                indicate.fillRect((this.screenWidth - 430) / data_len * i, 10, (this.screenWidth - 430) / data_len, 30)    //矩形填充
                indicate.strokeRect((this.screenWidth - 430) / data_len * i, 10, (this.screenWidth - 430) / data_len, 30)  //矩形边框


            }

        },

        // 绘制散点图
        ScatterChart(domName) {
            this.Scatter = this.$echarts.init(domName);
            // 基于刚刚准备好的 DOM 容器，初始化 EChart 实例
            // 横轴坐标
            // this.x = [];
            // for (let i = 0; i < this.ecgData.length; i++) {
            //   this.x.push(i);
            // }

            // this.DrawMarkPoint()
            // this.ConclusionPageData()
            // console.log(this.newTimeList)

            // datazoom滑块左右两侧时间刻度
            // var base = +new Date(this.newTimeList[0].substring(0, 4),
            //   this.newTimeList[0].substring(5, 7) - 1,
            //   this.newTimeList[0].substring(8, 10),
            //   this.newTimeList[0].substring(11, 13),
            //   this.newTimeList[0].substring(14, 16),
            //   this.newTimeList[0].substring(17, 19));

            let option = {
                title: {
                    // text: '我的心电散点图',
                    // subtext: '总点数'+this.r_num,//(iter_index+1),
                    //  bottom: 'center',
                    //  padding: [20,20,100,100]
                },

                toolbox: {
                    show: true,
                    feature: {
                        mark: { show: true },
                        dataZoom: { show: true },
                        dataView: { show: true, readOnly: false },
                        restore: { show: true },
                        saveAsImage: { show: true },
                        myTool: {
                            show: true,
                            title: "什么是散点图？",
                            // icon:'path://M578.064516 792.774194H445.935484v-165.161291c0-95.033806 40.23329-142.864516 137.083871-208.532645l7.498322-5.086968C658.861419 367.616 677.16129 345.154065 677.16129 297.290323A165.16129 165.16129 0 0 0 346.83871 297.290323H214.709677a297.290323 297.290323 0 1 1 594.580646 0c0 104.216774-43.272258 157.266581-144.582194 226.006709l-7.531355 5.12C593.754839 571.458065 578.064516 590.088258 578.064516 627.612903v165.161291z m-66.064516 231.225806a82.580645 82.580645 0 1 1 0-165.16129 82.580645 82.580645 0 0 1 0 165.16129z',
                            icon: 'path://M554.666667 874.666667a21.333333 21.333333 0 1 1-21.333334-21.333334 21.333333 21.333333 0 0 1 21.333334 21.333334z m0-149.333334V637.84a74.9 74.9 0 0 1 23.44-54.32c18.306667-17.26 36.286667-33.04 53.673333-48.306667 36-31.6 70-61.446667 94.566667-94.38C754.766667 402.74 768 364.34 768 320a234.666667 234.666667 0 1 0-469.333333 0 21.333333 21.333333 0 0 0 42.666666 0c0-105.866667 86.133333-192 192-192s192 86.133333 192 192c0 76.32-51.046667 121.126667-121.7 183.146667C586 518.666667 567.7 534.666667 548.84 552.466667A117.76 117.76 0 0 0 512 637.84V725.333333a21.333333 21.333333 0 0 0 42.666667 0z',
                            onclick: () => {
                                this.dialogVisible3 = true
                                // console.log(this.dialogVisible3)
                            }

                        }
                    },
                    top: 5,
                    right: "8%",
                },
                legend: {
                    top: 100,
                    itemWidth: 5,
                    itemGap: 15,
                    textStyle: {
                        fontSize: 20
                    }
                },
                grid: {
                    top: 70,

                    right: 50,
                },
                xAxis: [
                    {
                        type: 'value',
                        scale: true,
                        name: 'RRn(ms)',
                        axisLine: {
                            lineStyle: {
                                color: 'black',
                                width: 2,
                            },
                        },
                        axisLabel: {
                            formatter: '{value} ',
                            fontSize: 16,
                            inside: true
                        },
                        min: 0,//parseInt((min_rList-error_rList/2)/100)*100,
                        max: 2000,//parseInt((max_rList+error_rList/2)/100+1)*100,
                        splitLine: {
                            show: false,
                        },
                        splitNumber: 4,
                        nameLocation: 'center',
                        nameTextStyle: {
                            fontSize: 20,
                            padding: 0
                        }
                    }
                ],
                yAxis: [
                    {
                        type: 'value',
                        scale: true,
                        name: 'RRn+1(ms)',
                        axisLine: {
                            lineStyle: {
                                color: 'black',
                                width: 2,
                            },
                        },
                        axisLabel: {
                            formatter: '{value} ',
                            fontSize: 16,
                            inside: true
                        },
                        min: 0,//parseInt((min_rList-error_rList/2)/100)*100,
                        max: 2000,//parseInt((max_rList+error_rList/2)/100+1)*100,
                        splitLine: {
                            show: false,
                        },
                        splitNumber: 4,
                        nameLocation: 'center',
                        nameTextStyle: {
                            fontSize: 20,
                            padding: 0
                        }
                    }
                ],
                series: [
                    {
                        name: '检测结果',
                        type: 'scatter',
                        symbolSize: 2, //设定实心点的大小
                        itemStyle: { normal: { color: 'black', lineStyle: { color: 'red' } } },
                        data: this.data_rList//pot_list
                    },
                    {
                        // name: '辅助线1',
                        type: 'line',
                        data: [[0, 0], [2000, 2000]],
                        itemStyle: {
                            normal: {
                                color: 'gray', // 颜色置灰
                                width: 1, // 宽度1就行了
                                lineStyle: {
                                    type: 'dotted' // line样式为虚线
                                }
                            }
                        }

                    }, {
                        // name: '辅助线2',
                        type: 'line',
                        data: [[500, 0], [0, 500]],
                        itemStyle: {
                            normal: {
                                color: 'gray', // 颜色置灰
                                width: 1, // 宽度1就行了
                                lineStyle: {
                                    type: 'dotted' // line样式为虚线
                                }

                            }
                        }
                    }, {
                        // name: '辅助线3',
                        type: 'line',
                        data: [[1000, 0], [0, 1000]],
                        itemStyle: {
                            normal: {
                                color: 'gray', // 颜色置灰
                                width: 1, // 宽度1就行了
                                lineStyle: {
                                    type: 'dotted' // line样式为虚线
                                }
                            }
                        }
                    },
                    {
                        // name: '辅助线4',
                        type: 'line',
                        data: [[1500, 0], [0, 1500]],
                        itemStyle: {
                            normal: {
                                color: 'gray', // 颜色置灰
                                width: 1, // 宽度1就行了
                                lineStyle: {
                                    type: 'dotted' // line样式为虚线
                                }
                            }
                        }
                    },
                    {
                        // name: '辅助线5',
                        type: 'line',
                        data: [[2000, 0], [0, 2000]],
                        itemStyle: {
                            normal: {
                                color: 'gray', // 颜色置灰
                                width: 1, // 宽度1就行了
                                lineStyle: {
                                    type: 'dotted' // line样式为虚线
                                }
                            }
                        }
                    },
                ]
            };
            this.Scatter.setOption(option);
            // window.addEventListener("resize", () => {  //根据屏幕进行实时绘制
            // myChart.resize();
            // });
        },
        // 点击散点图 显示帮助信息框
        ScatterHelp(id) {
            this.dialogVisible2 = true
            console.log(id)
            this.index = id
            this.scatterHelp.title = images_name[id - 1]
            this.scatterHelp.abstract = scattercontent.Abstract[id - 1]
            this.scatterHelp.morphology = scattercontent.Content[id - 1]
            this.scatterHelp.Annotation = scattercontent.Annotation[id - 1]
            this.img_soucre = require(`../../../../../public/img/scatterInfo/${this.index}.png`)
        },
        // 正常心电散点图 帮助信息框
        ScatterHelp_Normal() {
            this.dialogVisible2 = true
            this.scatterHelp.title = images_name[0]
            this.scatterHelp.abstract = scattercontent.Abstract[0]
            this.scatterHelp.morphology = scattercontent.Content[0]
            this.scatterHelp.Annotation = scattercontent.Annotation[0]
            this.img_soucre = require(`../../../../../public/img/scatterInfo/1.png`)
        },
        dealNum(item) {
            console.log(item)
        },
        getInfo() {
            this.$http({
                url: this.$http.adornUrl('/personal/info/getBasicInfo'),
                method: 'get',
                params: this.$http.adornParams({ uid: this.uid })
            }).then(({ data }) => {
                if (data && data.code === 0) {
                    this.report_title.realName = data.realName
                    this.report_title.age = data.age
                    this.report_title.gender = data.gender
                    var genderMap = { 0: "女", 1: "男" }
                    this.report_title.gender = genderMap[this.report_title.gender]
                } else {
                    // this.getCaptcha()
                    this.$message.error(data.msg)
                }
            })
        }
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {

        this.deid = this.$route.query.dynamicRecordId
        this.createTime = this.$route.query.dynamicRecordCreateTime
        this.duration = this.$route.query.dynamicRecorDuration

        this.ECG_option = {
            // echarts工具栏
            animation: false,
            toolbox: {
                feature: {
                    dataZoom: { yAxisIndex: "none", show: false }, // 数据区域缩放
                    restore: { show: true }, // 重置
                    saveAsImage: { show: true }, // 导出图片
                    // 全屏
                    // myFull: {
                    //   show: true,
                    //   title: "全屏显示",

                    //   icon: "path://M85.333333 682.666667v128a128 128 0 0 0 128 128h128a42.666667 42.666667 0 0 0 0-85.333334H213.333333a42.666667 42.666667 0 0 1-42.666666-42.666666v-128a42.666667 42.666667 0 0 0-85.333334 0z m597.333334 256h128a128 128 0 0 0 128-128v-128a42.666667 42.666667 0 0 0-85.333334 0v128a42.666667 42.666667 0 0 1-42.666666 42.666666h-128a42.666667 42.666667 0 0 0 0 85.333334z m256-597.333334V213.333333a128 128 0 0 0-128-128h-128a42.666667 42.666667 0 0 0 0 85.333334h128a42.666667 42.666667 0 0 1 42.666666 42.666666v128a42.666667 42.666667 0 0 0 85.333334 0zM341.333333 85.333333H213.333333a128 128 0 0 0-128 128v128a42.666667 42.666667 0 0 0 85.333334 0V213.333333a42.666667 42.666667 0 0 1 42.666666-42.666666h128a42.666667 42.666667 0 0 0 0-85.333334z",
                    //   onclick: () => {
                    //     // 全屏查看
                    //     this.dialogVisible = true; // 打开弹窗
                    //     this.$nextTick(() => {

                    //       const chartFul = this.$refs.fullChart;
                    //       if (chartFul) {
                    //         this.initECGChart(document.getElementById("myChart2")); // 画图
                    //         this.UpdateECGChart()

                    //       }
                    //     });
                    //   },
                    // },

                },
                right: "8%",
                top: "0%",
            },

            grid: {
                left: 55 /*"50px"*/,
                right: 60 /*"15px"*/,
                top: 25 /*"15px"*/,
                bottom: 40 /*"15px"*/,
                borderWidth: "0",
            },
            legend: {
                selected: {
                    "显示/隐藏标志点": false
                },
                itemGap: 30,
                inactiveColor: '#444',
                textStyle: {
                    fontSize: 16
                }
            },
            xAxis: [{
                type: "value",
                interval: 100,
                boundaryGap: false,
                show: true,
                max: [],  //限制横坐标的长度
                splitLine: {
                    show: true,
                    lineStyle: {
                        // color: ["#e03433", "#FFB6C1", "##FFB6C1", "#FFB6C1", "#FFB6C1"],
                        color: "#e03433"
                    },
                },
                minorSplitLine: {
                    show: true,
                    lineStyle: {
                        // color: ["#e03433", "#FFB6C1", "##FFB6C1", "#FFB6C1", "#FFB6C1"],
                        color: "#FFB6C1"
                    },
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                axisLine: {
                    show: false,
                }
            },
            {
                type: "value",
                max: 100,
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                axisLine: {
                    show: false,
                }
            }],
            yAxis: [{
                type: "value",
                interval: 0.5,
                boundaryGap: false,
                splitLine: {
                    show: true,
                    lineStyle: {
                        // color: ["#e03433", "#FFB6C1", "##FFB6C1", "#FFB6C1", "#FFB6C1"],
                        color: "#e03433"
                    },
                },
                minorSplitLine: {
                    show: true,
                    lineStyle: {
                        // color: ["#e03433", "#FFB6C1", "##FFB6C1", "#FFB6C1", "#FFB6C1"],
                        color: "#FFB6C1"
                    },
                },

                max: 2, //设置最大值
                min: -7 * leadsInterval - leadsInterval / 2, //最小值（与leadsInterval 导联间隔有关）
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
            },
            // 新的yaxis是用来固定结论框在y方向的位置的
            {
                type: "value",
                max: 17,
                min: 0,
                splitLine: {
                    show: false,
                },
                axisTick: {
                    show: false,
                },
                axisLabel: {
                    show: false,
                },
                axisLine: {
                    show: false,
                }
            }],
            dataZoom: [
                // 横轴滑块
                {
                    type: "slider",
                    show: true,
                    filterMode: 'none',

                    labelFormatter: '',
                    xAxisIndex: [0],
                    startValue: 0,
                    endValue: 5000, //初始化滚动条为10s
                    textStyle: {
                        show: false,
                        fontSize: 14,
                        color: '#000',
                        fontFamily: 'Arial'
                    },
                    backgroundColor: "#fff",
                    showDetail: true, //即拖拽时候是否显示详细数值信息 默认true
                    // 控制拖动条最大最小值，现设为固定10s
                    zoomlock: true,
                    brushSelect: false,
                    maxValueSpan: 5000,
                    minValueSpan: 5000,
                },
                // 纵轴滑块
                {
                    textStyle: {
                        show: false,
                    },
                    brushSelect: false,
                    labelFormatter: '',
                    show: true,
                    yAxisIndex: 0,
                    filterMode: 'empty',
                    width: 20,
                    height: '90%',
                    right: '2%',
                    showDataShadow: false,
                    start: 25 + leadsInterval * 12.5,  // 与leadsInterval导联间隔有关
                    end: 100,
                }
            ],
            graphic: [
                {
                    type: 'group',
                    bounding: 'raw',
                    top: (this.screenWidth) * 0.02,
                    left: 20 + (this.screenWidth) * 0.010,
                    z: 100,
                    children: [
                        {
                            type: 'text',
                            left: 80,
                            z: 100,
                            style: {
                                fill: '#000',
                                text: '5mm',
                                font: ' 16px sans-serif'
                            }
                        },
                        // {
                        //     type: 'line',
                        //     left: 60 + (this.screenWidth) * 0.012,
                        //     z: 100,
                        //     shape: {
                        //         x1: 0,
                        //         y1: 16,
                        //         x2: (this.screenWidth) * 0.014,
                        //         y2: 16,
                        //     },
                        //     style: {
                        //         fill: '#000',
                        //         lineWidth: 3
                        //     }
                        // },
                        {
                            type: 'text',
                            left: 150,
                            z: 100,
                            style: {
                                fill: '#000',
                                text: '增益10mm/mv',
                                font: ' 16px sans-serif'
                            }
                        },
                        {
                            type: 'text',
                            left: 300,
                            z: 100,
                            style: {
                                fill: '#000',
                                text: '走速25mm/s',
                                font: ' 16px sans-serif'
                            }
                        },
                        {
                            type: 'text',
                            left: 500,
                            z: 100,
                            style: {
                                fill: '#000',
                                text: 'HWD-ID 2103020017',
                                font: ' 16px sans-serif'
                            }
                        }
                    ]
                },
            ],
            dataset: [
                {
                    // 序号为 0 的 dataset：心电图
                    source: [

                    ]
                },
                {
                    // 序号为 1 的 dataset:结论
                    source: [
                    ]
                },
                // 下面的数据集用来展示标志点，序号以此类推
                {
                    source: [
                    ]
                },
                {
                    source: [
                    ]
                },
                {
                    source: [
                    ]
                },
                {
                    source: [
                    ]
                },
                {
                    source: [
                    ]
                },
                {
                    source: [
                    ]
                },
                //8 标志物
                {
                    source: [
                    ],
                },
                //9 标志物标签
                {
                    source: [
                        [2, 2, 2, 2, 2, 2, 2, 2],
                        [0, -1 * leadsInterval, -2 * leadsInterval, -3 * leadsInterval, -4 * leadsInterval, -5 * leadsInterval, -6 * leadsInterval, -7 * leadsInterval]
                    ]
                },
                //10 心电图比例尺
                {
                    source: [
                        [6, 8],
                        [16, 16]
                    ]
                }
            ],
            series: [
                {
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    datasetIndex: 0,
                    symbol: "none" /*去掉小圆点*/,
                    // name: 'I导联',
                    name: '心电图',
                    type: "line",
                    encode: {
                        x: 0,
                        y: 1
                    },
                    seriesLayoutBy: "row",
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                color: "#000" /*折线的颜色*/,
                                width: 1.5
                            },
                            color: "#000"
                        },

                    },
                },
                {
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    datasetIndex: 0,
                    symbol: "none" /*去掉小圆点*/,
                    // name: 'Ⅱ导联',
                    name: '心电图',
                    type: "line",
                    encode: {
                        x: 0,
                        y: 2
                    },
                    seriesLayoutBy: "row",
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                color: "#000" /*折线的颜色*/,
                                width: 1.5
                            },
                            color: "#000"
                        },
                    },
                },
                {
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    datasetIndex: 0,
                    symbol: "none" /*去掉小圆点*/,
                    // name: 'Ⅲ导联',
                    name: '心电图',
                    type: "line",
                    encode: {
                        x: 0,
                        y: 3
                    },
                    seriesLayoutBy: "row",
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                color: "#000" /*折线的颜色*/,
                                width: 1.5
                            },
                            color: "#000"
                        },
                    },
                },
                {
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    datasetIndex: 0,
                    symbol: "none" /*去掉小圆点*/,
                    // name: 'aVR导联',
                    name: '心电图',
                    type: "line",
                    encode: {
                        x: 0,
                        y: 4
                    },
                    seriesLayoutBy: "row",
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                color: "#000" /*折线的颜色*/,
                                width: 1.5
                            },
                            color: "#000"
                        },
                    },
                },
                {
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    datasetIndex: 0,
                    symbol: "none" /*去掉小圆点*/,
                    // name: 'aVF导联',
                    name: '心电图',
                    type: "line",
                    encode: {
                        x: 0,
                        y: 5
                    },
                    seriesLayoutBy: "row",
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                color: "#000" /*折线的颜色*/,
                                width: 1.5
                            },
                            color: "#000"
                        },
                    },
                },
                {
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    datasetIndex: 0,
                    symbol: "none" /*去掉小圆点*/,
                    // name: 'aVL导联',
                    name: '心电图',
                    type: "line",
                    encode: {
                        x: 0,
                        y: 6
                    },
                    seriesLayoutBy: "row",
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                color: "#000" /*折线的颜色*/,
                                width: 1.5
                            },
                            color: "#000"
                        },
                    },
                },
                {
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    datasetIndex: 0,
                    symbol: "none" /*去掉小圆点*/,
                    // name: 'V1导联',
                    name: '心电图',
                    type: "line",
                    encode: {
                        x: 0,
                        y: 7
                    },
                    seriesLayoutBy: "row",
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                color: "#000" /*折线的颜色*/,
                                width: 1.5
                            },
                            color: "#000"
                        },
                    },
                },
                {
                    xAxisIndex: 0,
                    yAxisIndex: 0,
                    datasetIndex: 0,
                    symbol: "none" /*去掉小圆点*/,
                    type: "line",
                    name: '心电图',
                    // name: 'V5导联',
                    encode: {
                        x: 0,
                        y: 8
                    },
                    seriesLayoutBy: "row",
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                color: "#000" /*折线的颜色*/,
                                width: 1.5
                            },
                            color: "#000"
                        },
                    },
                },
                {
                    xAxisIndex: 0,
                    yAxisIndex: 1,
                    datasetIndex: 1,
                    z: 10,
                    name: '结论',
                    type: "scatter",
                    symbol: 'roundRect',
                    symbolSize: 1,
                    itemStyle: {
                        borderColor: 'rgb(199,86,83)',
                        borderWidth: 1,
                        color: '#fff',
                    },
                    encode: { x: 24, y: 25 },
                    seriesLayoutBy: "row",
                    label: {
                        normal: {
                            position: 'left',
                            show: true,
                            formatter: function (e) {
                                // console.log(e.value)
                                var value = e.value
                                var dxtb_flag = '无'
                                if (value[6] > 0) {
                                    dxtb_flag = '有'
                                }
                                var qtycmap = { 0: "ST-T正常", 1: "T波倒置", 2: "ST段抬高", 3: "ST段压低" }//心梗相关疾病的map
                                var methodmap = { rule: "规则模型", dl: "深度学习模型" }
                                var content =
                                    `{term1|心率：${value[0]}次/分钟}{term1|室性心动过速：${value[8]}%}{term2|短PR综合征：${value[16]}%}\n` +
                                    `{term1|窦性心动过速：${value[1]}%}{term1|交界性逸博：${value[9]}%}{term2|变异型预激综合征：${value[17]}%}\n` +
                                    `{term1|窦性心动过缓：${value[2]}%}{term1|室性逸搏：${value[10]}%}{term2|ST段抬高：${value[18]}%}\n` +
                                    `{term1|窦性心律不齐：${value[3]}%}{term1|房颤：${value[11]}%}{term2|ST段压低：${value[19]}%}\n` +
                                    `{term1|房性早搏：${value[4]}}{term1|房扑：${value[12]}%}{term2|T波倒置：${value[20]}%}\n` +
                                    `{term1|交界性早搏：${value[5]}%}{term1|左束支传导阻滞：${value[13]}%}{term2|T波高尖：${value[21]}%}\n` +
                                    `{term1|室性早搏${value[6]}%}{term1|右束支传导阻滞：${value[14]}%}{term2|病理性Q波：${value[22]}%}\n` +
                                    `{term1|室上性心动过速：${value[7]}%}{term1|典型预激综合征：${value[15]}%}\n` +
                                    `{fragment1|时间：${value[26]}}{blank|}{fragment2|${methodmap[value[23]]}}{blank|}{fragment3|Android}`
                                return content
                            },
                            backgroundColor: 'rgb(255 255 255 / 90%)',
                            // borderColor: '#333',
                            borderColor: 'rgb(199,86,83)',
                            borderWidth: 2,
                            borderRadius: 5,
                            padding: 10,
                            color: '#000',
                            shadowBlur: 3,
                            shadowColor: '#cfcfcf',
                            shadowOffsetX: 0,
                            shadowOffsetY: 3,
                            lineHeight: 18,
                            rich: {
                                term1: {
                                    fontSize: 15,
                                    width: 170
                                },
                                term2: {
                                    fontSize: 15,
                                    width: 170
                                },
                                fragment1: {
                                    align: 'left',
                                    fontSize: 14,
                                    lineHeight: 22,
                                    backgroundColor: '#696969',
                                    color: '#fff',
                                    borderRadius: 8,
                                    padding: 5
                                },
                                fragment2: {
                                    align: 'left',
                                    fontSize: 14,
                                    lineHeight: 22,
                                    backgroundColor: 'rgb(138 175 247)',
                                    color: '#fff',
                                    borderRadius: 8,
                                    padding: 5
                                },
                                fragment3: {
                                    align: 'left',
                                    fontSize: 14,
                                    lineHeight: 25,
                                    backgroundColor: '#DC9E7B',
                                    color: '#fff',
                                    borderRadius: 8,
                                    padding: 5
                                },
                                blank:
                                {
                                    width: 10
                                }
                            }
                        }
                    }
                },
                {
                    xAxisIndex: 0,
                    datasetIndex: 2,
                    type: "scatter",
                    seriesLayoutBy: "row",
                    name: '显示/隐藏标志点',
                    z: 3,
                    symbolSize: 7,
                    itemStyle: {
                        borderColor: "#0652DD",
                        borderWidth: 2,
                        color: '#f56c6c00',
                    },
                },
                {
                    xAxisIndex: 0,
                    datasetIndex: 3,
                    type: "scatter",
                    seriesLayoutBy: "row",
                    name: '显示/隐藏标志点',
                    z: 3,
                    symbolSize: 7,
                    itemStyle: {
                        borderColor: "#EE5A24",
                        borderWidth: 2,
                        color: '#f56c6c00',
                    },
                },
                {
                    xAxisIndex: 0,
                    datasetIndex: 4,
                    type: "scatter",
                    seriesLayoutBy: "row",
                    name: '显示/隐藏标志点',
                    z: 3,
                    symbolSize: 7,
                    itemStyle: {
                        borderColor: "#ED4C67",
                        borderWidth: 2,
                        color: '#f56c6c00',
                    },
                },
                {
                    xAxisIndex: 0,
                    datasetIndex: 5,
                    type: "scatter",
                    seriesLayoutBy: "row",
                    name: '显示/隐藏标志点',
                    z: 3,
                    symbolSize: 7,
                    itemStyle: {
                        borderColor: "#7d5fff",
                        borderWidth: 2,
                        color: '#f56c6c00',
                    },
                },
                {
                    xAxisIndex: 0,
                    datasetIndex: 6,
                    type: "scatter",
                    seriesLayoutBy: "row",
                    name: '显示/隐藏标志点',
                    z: 3,
                    symbolSize: 7,
                    itemStyle: {
                        borderColor: "#009432",
                        borderWidth: 2,
                        color: '#f56c6c00',
                    },
                },
                {
                    connectNulls: true,
                    xAxisIndex: 0,
                    datasetIndex: 7,
                    type: "scatter",
                    seriesLayoutBy: "row",
                    name: '显示/隐藏标志点',
                    z: 3,
                    symbolSize: 7,
                    itemStyle: {
                        borderColor: "yellow",
                        borderWidth: 2,
                        color: '#f56c6c00',
                    },
                },
                {

                    xAxisIndex: 1,
                    symbol: "none" /*去掉小圆点*/,
                    datasetIndex: 8,
                    type: "line",
                    seriesLayoutBy: "row",
                    // name: '标志物',
                    z: 4,
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                color: "#000" /*折线的颜色*/,
                                width: 3
                            },
                            color: "#000" /*图例(legend)的颜色,不是图例说明文字的颜色*/,
                        },
                    },
                },
                {
                    xAxisIndex: 1,
                    seriesLayoutBy: "row",
                    datasetIndex: 9,
                    symbolSize: 10,
                    type: "scatter",
                    // name:'标志物的标签',
                    z: 5,
                    itemStyle: {
                        color: "#ffffff00"
                    },
                    label: {
                        normal: {
                            position: 'bottom',
                            show: true,
                            formatter: function (e) {
                                var itemlabel = ["Ⅰ", "Ⅱ", "Ⅲ", "aVR", "aVL", "aVF", "V1", "V5"];
                                return itemlabel[e.dataIndex]
                            },
                            fontSize: 18
                        }
                    }
                },
                {
                    // 比例尺
                    xAxisIndex: 1,
                    yAxisIndex: 1,
                    datasetIndex: 10,
                    type: 'line',
                    symbol: "none" /*去掉小圆点*/,
                    seriesLayoutBy: "row",
                    z: 4,
                    itemStyle: {
                        normal: {
                            lineStyle: {
                                color: "#000" /*折线的颜色*/,
                                width: 3
                            },
                            color: "#000" /*图例(legend)的颜色,不是图例说明文字的颜色*/,
                        },
                    },
                }
            ]
        };
    },
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        // this.ECGChart(document.getElementById("myChart"));   
        this.initECGChart(document.getElementById("myChart"));
        this.myChart.setOption(this.ECG_option);
        this.getConclusion();
        this.EndTime();
        this.TotalTime();
        this.getInfo();
        window.onresize = () => {
            return (() => {
                window.screenHeight = window.innerHeight;
                this.screenHeight = window.screenHeight;
                window.screenWidth = window.innerWidth;
                this.screenWidth = window.screenWidth;
            })();
        };

    },
    beforeCreate() {
        //展示前进行刷新，就不会出现缩小页面后canvas颜色条消失的情况
        if (location.href.indexOf("#reloaded") == -1) {
            location.href = location.href + "#reloaded";
            location.reload();
        }
    }, //生命周期 - 创建之前
    beforeMount() { }, //生命周期 - 挂载之前
    beforeUpdate() { }, //生命周期 - 更新之前
    updated() { }, //生命周期 - 更新之后
    beforeDestroy() { }, //生命周期 - 销毁之前
    destroyed() { }, //生命周期 - 销毁完成
    activated() { }, //如果页面有keep-alive缓存功能，这个函数会触发
};
</script>
<style lang="less" scoped>
p {
    text-indent: 2em;
}

::v-deep .el-tabs__item {
    font-size: 16px !important;
    font-weight: bold
}

::v-deep .el-slider__marks-text {
    width: min-content;
    font-size: 15px;
    color: black;
}

::v-deep .el-slider__runway {
    background-color: #e4e7ed00
}

::v-deep .el-slider__stop {
    height: 8px;
    width: 8px;
    background-color: #e4e7ed00
}

::v-deep .el-slider__bar {
    background-color: #f5f7fa00
}

::v-deep .el-slider__button-wrapper {
    .el-slider__button {
        width: 16px;
        height: 25px;
        border-radius: 0;
        background: rgb(231 239 255);
        border: solid 2px rgb(178 201 251);
    }
}

.image {
    width: calc(95%);
    cursor: pointer;
    margin-bottom: 20px;
    display: inline-block;
    border: solid 2px #576ebb;
    border-radius: 20px;
}

::v-deep .tabs {
    text-align: center;
    top: 50px;
    left: 50px;
    ;
    position: relative;
}

::v-deep .el-tabs {
    color: #000;
    left: 0px;
    top: 0px;
    position: relative;
}

// ######### 【心电图/散点图】tab 样式修改 ###############
// /* 去除灰色横条 */
// ::v-deep .el-tabs__nav-wrap::after {
//     position: static !important;
// }

// /* 设置滑块颜色 */
// ::v-deep .el-tabs__active-bar {
//     background-color: #428bca !important;
// }

// /* 设置滑块停止位置 */
// ::v-deep .el-tabs__active-bar.is-top {
//     height: 30px;
//     width: 80px ! important;
//     border-radius: 15px;
//     top: 0px !important;
//     left: -15px !important;
//     position: absolute !important;
//     z-index: 1;
// }

// /* 设置当前选中样式 */
// ::v-deep .el-tabs__item.is-active {
//     color: #ffffff !important;
//     z-index: 2;
// }

// /* 设置未被选中样式 */
// ::v-deep .el-tabs__item {
//     padding: 0 20px !important;
//     width: 80px;
//     height: 30px;
//     box-sizing: border-box;
//     display: inline-block;
//     position: relative !important;
//     color: #000000 !important;
//     z-index: 2;
//     line-height: 30px
// }

.image_name {
    display: block;
    background: #edecec;
    width: 90%;

}

.image_name_td {
    height: 40px;
    display: table-cell;
    vertical-align: middle;
    font-size: 15px
}

::v-deep .el-dialog__body {
    padding: 0px 20px;
    padding-bottom: 10px;
}

// .scroll {
//     overflow-y: scroll;
//     height: 650px;
// }

// .scroll::-webkit-scrollbar {
//     /*滚动条高宽度*/
//     width: 8px;
//     height: 4px;
// }

// .scroll::-webkit-scrollbar-thumb {
//     /*滚动条滑块*/
//     border-radius: 3px;
//     box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
//     background: #c7c4c4;
// }

// .scroll::-webkit-scrollbar-track {
//     /*滚动条里面轨道*/
//     border-radius: 10px;
//     background: #ededed;
//     box-shadow: inset 0 0 5px rgba(0, 0, 0, .1);
// }
//
</style>
<style lang="less">
//   .el-tooltip__popper {
//     font-size: 15px;
//   }
</style>